<template>
  <div class="wap-mine-setting">
    <headerBar :title="$t('she-jiao-mei-ti-0')" @back="back"></headerBar>
    <div class="wap-page-form">
      <div class="form-item">
        <div class="label">Facebook</div>
        <Field
          v-model="form.Facebook"
          label=""
          :placeholder="$t('qing-shu-ru-facebook-di-zhi')"
        ></Field>
      </div>
      <div class="form-item">
        <div class="label">Twitter</div>
        <Field
          v-model="form.Twitter"
          label=""
          :placeholder="$t('qing-shu-ru-twitter-di-zhi')"
        ></Field>
      </div>
      <div class="form-item">
        <div class="label">Google</div>
        <Field
          v-model="form.Google"
          label=""
          :placeholder="$t('qing-shu-ru-google-di-zhi')"
        ></Field>
      </div>
      <div class="form-item">
        <div class="label">Youtube</div>
        <Field
          v-model="form.Youtube"
          label=""
          :placeholder="$t('qing-shu-ru-youtube-di-zhi')"
        ></Field>
      </div>
      <div class="form-item">
        <div class="label">Instagram</div>
        <Field
          v-model="form.Instagram"
          label=""
          :placeholder="$t('qing-shu-ru-instagram-di-zhi')"
        ></Field>
      </div>
      <div class="form-btn">
        <Button @click="submit">{{ $t('ti-jiao') }}</Button>
      </div>
    </div>
  </div>
</template>
<script>
import headerBar from '@/components/header'
import { Field, Button } from 'vant'
import { shopSetting, shopInfo } from '@/api/shop'
export default {
  components: {
    headerBar,
    Field,
    Button
  },
  data() {
    return {
      form: {
        Facebook: '',
        Twitter: '',
        Youtube: '',
        Instagram: '',
        Google: ''
      },
      shopInfo: {}
    }
  },
  computed: {},
  mounted() {
    this.init()
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    init() {
      shopInfo().then((res) => {
        let data = res.data
        this.shopInfo = res.data
        this.form = {
          Facebook: data.Facebook,
          Twitter: data.Twitter,
          Youtube: data.Youtube,
          Instagram: data.Instagram,
          Google: data.Google
        }
      })
    },
    submit() {
      shopSetting({
        ...this.shopInfo,
        Facebook: this.form.Facebook,
        Twitter: this.form.Twitter,
        Youtube: this.form.Youtube,
        Instagram: this.form.Instagram,
        Google: this.form.Google
      }).then((res) => {
        if (res.code == 0) {
          this.$toast(this.$t('xiu-gai-cheng-gong'))
          setTimeout(() => {
            this.back()
          }, 1500)
        } else {
          this.$toast(res.msg)
        }
      })
    }
  }
}
</script>